.modal-content {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    min-height: 300px;
    min-width: 300px;
    // position: relative;
    .modal__inner {
        position: relative;
        width: 100%;
        height: 100%;
    }
}