@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');
$maxWidth: 1800px;
$minWidth: 700px;

html, body, .app, #root, .app__wrapper {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: #dcdcdc;
  font-family: 'Raleway', sans-serif;
}

.app {
    
  .app__wrapper {
    padding-top: 15px;
    display: grid;
    grid-template-columns: 200px auto 200px;
    grid-template-areas: 
      "sidebar content right";
    max-width: 1500px;
    margin: 0 auto;

  }
  .app__content {
    width: 100%;
    height: 100%;
    grid-area: content;

    min-width: $minWidth;
    max-width: $maxWidth;
    margin: 0 auto;

    .ui.grid {
      margin: 0;
    }
  }
}

.login_wrapper {
  width: 90%;
  max-width: $maxWidth;
  margin: 0 auto;
  height: 100%;
  .form {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    // padding: 2em;
  }
}