@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tally__date{display:flex;justify-content:center;align-items:center}.react-datepicker-wrapper{padding:0 10px}.react-datepicker__input-container input{border-radius:4px;padding:10px 8px;border:1px solid #e3e8ee;text-align:center}.memo_modal_content{padding:12px;height:220px}.memo_modal_content .ui.form{display:flex;justify-content:space-between;flex-direction:column;height:100%}.memo_modal_content .ui.form .button-cont{display:flex;justify-content:space-around}
.modal-content{position:absolute;display:flex;justify-content:center;align-items:center;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);background-color:#fff;min-height:300px;min-width:300px}.modal-content .modal__inner{position:relative;width:100%;height:100%}
.sidebar{width:160px;height:100%;justify-self:end}.sidebar .sidebar__item{padding:8px}.sidebar .sidebar__item-selected{background-color:#eee}
html,body,.app,#root,.app__wrapper{width:100%;height:100%;margin:0;background-color:#dcdcdc;font-family:"Raleway",sans-serif}.app .app__wrapper{padding-top:15px;display:grid;grid-template-columns:200px auto 200px;grid-template-areas:"sidebar content right";max-width:1500px;margin:0 auto}.app .app__content{width:100%;height:100%;grid-area:content;min-width:700px;max-width:1800px;margin:0 auto}.app .app__content .ui.grid{margin:0}.login_wrapper{width:90%;max-width:1800px;margin:0 auto;height:100%}.login_wrapper .form{position:absolute;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%)}
