
.tally__date {
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.react-datepicker-wrapper {
    padding: 0 10px;
}
.react-datepicker__input-container {
    input {
        border-radius: 4px;
        padding: 10px 8px;
        border: 1px solid #e3e8ee;
        text-align: center;
    }
}
.memo_modal_content {
    padding: 12px;
    height: 220px;
    .ui.form 
    {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;

        .button-cont {
            display: flex;
            justify-content: space-around;
        }
    }
}