.sidebar {
    width: 160px;
    height: 100%;
    // position: absolute;
    // left: 0;
    // background-color: green;
    justify-self: end;

    .sidebar__item {
        padding: 8px;
    }
    .sidebar__item-selected {
        background-color: #eeeeee;
    }
}